import { isRouteErrorResponse } from '@remix-run/react'
import { useEffect } from 'react'

import useCurrentLocation from './useCurrentLocation'

import { apiPaths } from '~/constants/paths'

export default function useServerReport({
  data,
  error,
}: {
  data?: unknown
  error?: unknown
}) {
  const [url] = useCurrentLocation()

  useEffect(() => {
    if (isRouteErrorResponse(error) && error.status === 404) return

    fetch(apiPaths.REPORT, {
      method: 'POST',
      body: JSON.stringify({
        data,
        url,
        error:
          error instanceof Error
            ? JSON.stringify(error, Object.getOwnPropertyNames(error))
            : null,
      }),
      headers: { 'Content-Type': 'application/json' },
    }).catch(() => {})
  }, [data, url, error])
}
